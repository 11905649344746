import React from 'react'
import { Query, Mutation } from 'react-apollo'
import icon_menu_hakurei from 'img/button_menu_hakurei.png'
import icon_menu_moriya from 'img/button_menu_moriya.png'
import { GET_MENU_STATE_QUERY, SET_MENU_STATE_QUERY } from 'src/queries'

export default () => (
  <Query query={GET_MENU_STATE_QUERY}>
    {({ data }) => (
      <Mutation
        mutation={SET_MENU_STATE_QUERY}
        variables={{ isMenuOpen: !data.isMenuOpen }}
      >
        {setMenuState => (
          <button
            className="menu_button"
            onClick={() =>
              setMenuStateAndFixBody(setMenuState, data.isMenuOpen)
            }
          >
            <img src={data.isMenuOpen ? icon_menu_moriya : icon_menu_hakurei} />
          </button>
        )}
      </Mutation>
    )}
  </Query>
)

const setMenuStateAndFixBody = (setMenuState, isMenuOpen) => {
  if (isMenuOpen) {
    const scrollTop = Math.abs(parseInt(document.body.style.top, 10))
    document.body.classList.remove('fixed')
    document.body.style.top = 0
    window.scrollTo({
      top: scrollTop,
    })
  } else {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    document.body.classList.add('fixed')
    document.body.style.top = '-' + scrollTop + 'px'
  }

  setMenuState()
}
