module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6T26N14593"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/opt/buildhome/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
