import React from 'react'
import { Mutation } from 'react-apollo'
import { navigate } from 'gatsby'
import { SET_MENU_STATE_QUERY } from 'src/queries'
import { isUnderConstruction } from 'src/commonFunctions'

export default ({ content }) => {
  const isdisabled = isUnderConstruction(content.SLUG)
  const clickEvent = setMenuOpen => {
    setMenuOpen()
    document.body.classList.remove('fixed')
    document.body.style.top = 0
    navigate(content.TO)
  }
  const titleLine = content.TITLE.split('<br />')
  return (
    <Mutation mutation={SET_MENU_STATE_QUERY} variables={{ isMenuOpen: false }}>
      {setMenuOpen => (
        <div
          className={`menu_icon ${isdisabled ? 'gray_out' : ''}`}
          onClick={() => {
            !isdisabled && clickEvent(setMenuOpen)
          }}
        >
          <div>
            <img src={content.IMAGE} />
          </div>
          <p className="menu_title">{titleLine[0]}</p>
          <p className="menu_title">{titleLine[1]}</p>
        </div>
      )}
    </Mutation>
  )
}
