// gatsby-nodeからもアプリケーション側からも呼び出す定数、関数をまとめている
// gatsby-nodeから呼び出す場合、constantsやcommonFunctionsとまとめられない
// gatsby-node内で__BASE_URL__等一部の変数が使えないため
import { parse } from 'date-fns'

const addLanguage = basename => {
  if (process.env.LANGUAGE_ALIAS === 'ja') {
    return basename
  }
  return `${basename}-${process.env.LANGUAGE_ALIAS}`
}

export const REPORT_TAGS = ['report', 'report-cn', 'report-ko', 'report-en']
export const COLUMN_TAGS = ['column', 'column-cn', 'column-ko', 'column-en']

export const ARTICLE_TYPE = {
  POST: 'post',
  INTERVIEW: 'interview',
  REPORT: 'report',
  COLUMN: 'column',
  NOVEL: 'novel',
  COMIC: 'comic',
  INDEXINTERVIEW: 'index_interview',
  INDEXNOVEL: 'index_novel',
  INDEXCOMIC: 'index_comic',
  GAMEREVIEW: addLanguage('game-review'),
  MUSICREVIEW: addLanguage('music-review'),
  FANZINEREVIEW: addLanguage('book-review'),
  NEWS: addLanguage('news'),
  WHERE: addLanguage('where-are-you'),
  EASTERNPLAYING: addLanguage('eastern-playing'),
  NEWENTRY: 'new_post',
  FEATURE: 'feature',
}

export const PAGE_METAS = {
  HOME: {
    TITLE: 'HOME',
    POST_URL: '/',
    CLASS_NAME: 'home',
  },
  CHARACTERS: {
    TITLE: 'character_introduction',
    POST_URL: 'characters',
    CLASS_NAME: 'characters',
  },
  INTERVIEWS: {
    TITLE: 'interview',
    POST_URL: 'interviews',
    CLASS_NAME: 'interviews',
  },
  REPORTS: {
    TITLE: 'report',
    POST_URL: 'reports',
    CLASS_NAME: 'reports',
  },
  COLUMNS: {
    TITLE: 'column',
    POST_URL: 'columns',
    CLASS_NAME: 'columns',
  },
  NOVELS: {
    TITLE: 'novel',
    POST_URL: 'novels',
    CLASS_NAME: 'novels',
  },
  COMICS: {
    TITLE: 'comic',
    POST_URL: 'comics',
    CLASS_NAME: 'comics',
  },
  SERIES: {
    TITLE: 'series',
    POST_URL: 'series',
    CLASS_NAME: 'pickup',
  },
  GAME_REVIEW: {
    TITLE: 'game_review',
    POST_URL: 'game_review',
    CLASS_NAME: 'game_review',
  },
  MUSIC_REVIEW: {
    TITLE: 'music_review',
    POST_URL: 'music_review',
    CLASS_NAME: 'music_review',
  },
  FANZINE_REVIEW: {
    TITLE: 'book_review',
    POST_URL: 'book_review',
    CLASS_NAME: 'book_review',
  },
  NEWS: {
    TITLE: 'news',
    POST_URL: 'news',
    CLASS_NAME: 'news',
  },
  WHERE: {
    TITLE: 'about',
    POST_URL: 'where_are_you',
    CLASS_NAME: 'where',
  },
  EASTERNPLAYING: {
    TITLE: 'eastern_playing',
    POST_URL: 'eastern_playing',
    CLASS_NAME: 'eastern_playing',
  },
  NEWENTRY: {
    TITLE: 'new_post',
    POST_URL: 'new_post',
    CLASS_NAME: 'new_post',
  },
  FEATURE: {
    TITLE: 'feature',
    POST_URL: 'feature',
    CLASS_NAME: 'feature',
  },
  CREATION: {
    TITLE: 'creation',
    POST_URL: 'creation',
    CLASS_NAME: 'creation',
  },
  CATEGORIES: (categoryName, slug) => ({
    TITLE: `${categoryName}`,
    POST_URL: `categories/${slug}`,
    CLASS_NAME: `categories`,
  }),
  TAGS: (tagName, slug) => ({
    TITLE: `${tagName}`,
    POST_URL: `tags/${slug}`,
    CLASS_NAME: `tags`,
  }),
}

// 記事系のコンポネントに渡す共通のobjectをまとめて作る
export const createArticleBaseInfo = node => ({
  id: node.id,
  title: node.title,
  slug: node.slug,
  date: node.date,
  modified: node.modified || null,
  categories: node.categories,
  tags: node.tags,
  ogpSettings: node.ogpSettings,
  isOfficial: node.isOfficial,
})

// urlからslugをとる
// 例 "https://stg.thwm.cfbx.jp/type/slug" => "slug"
export const convertUrlToSlug = url => {
  if (!url || typeof url !== 'string') return null
  // urlの末尾が/で終わる場合動かないので末尾の/を取る
  const formattedString = url[url.length - 1] === '/' ? url.slice(0, -1) : url
  return formattedString.substr(formattedString.lastIndexOf('/') + 1)
}

const getNextArticleSlug = infinityscrolllink => {
  if (infinityscrolllink && infinityscrolllink.url) {
    return convertUrlToSlug(infinityscrolllink.url)
  }
  return null
}

const createNextArticleInfo = targetArticle =>
  targetArticle && {
    id: targetArticle.id,
    type: targetArticle.type,
    slug: targetArticle.slug,
    title: targetArticle.title,
    date: targetArticle.date,
    ogpSettings: targetArticle.ogpSettings,
    isOfficial: targetArticle.isOfficial,
    linkToIndex: targetArticle.linkToIndex,
  }

// 必ず日付の降順にソートされた記事一覧を第一引数に渡すこと
// 1記事作成ごとに呼ばれるメソッドのため、パフォーマンス観点からこのメソッド内でのソートはしない
export const createRecommendArticles = (
  sortedArticles,
  infinityScrollLink,
  currentSlug,
  currentType
) => {
  const nextSlug = getNextArticleSlug(infinityScrollLink.infinityscrolllink)

  const removedCurrentArticleArticles = sortedArticles.filter(
    article => article.slug !== currentSlug
  )

  const nextArticle = removedCurrentArticleArticles.find(
    article => article.slug === nextSlug
  )
  const sameTypeLatestArticles = removedCurrentArticleArticles
    .filter(article => article.type === currentType)
    .slice(0, 2)
  const latestArticles = removedCurrentArticleArticles.slice(0, 2)

  return {
    nextArticle: createNextArticleInfo(nextArticle),
    sameTypeLatestArticles: sameTypeLatestArticles.map(article =>
      createNextArticleInfo(article)
    ),
    latestArticles: latestArticles.map(article =>
      createNextArticleInfo(article)
    ),
  }
}

const retrieveUrl = imgTag => {
  // TODO(かねこ): リンクとか動画とか来ちゃったときの対策をする
  const regex = /src="([^"]*)"/
  return (imgTag.match(regex) || [])[1]
}

const retrieveAlt = imgTag => {
  const regex = /alt="([^"]*)"/
  return (imgTag.match(regex) || [])[1]
}

export const createNovelsWithImages = (items, novels) =>
  items
    .map(item => {
      if (!item.link) return null
      const currentSlug = convertUrlToSlug(item.link.url)
      return novels.nodes.find(node => node.slug === currentSlug)
    })
    .filter(a => a)
    .map(node => ({
      ...node,
      imgList: extractionImg(node.novelContent.contents),
    }))

export const findNovelsWithImages = (items, novelsWithImages) =>
  items
    .map(item => {
      if (!item.link) return null
      const currentSlug = convertUrlToSlug(item.link.url)
      return novelsWithImages.find(node => node.slug === currentSlug)
    })
    .filter(a => a)

export const combineNovelsWithImages = novels =>
  novels.map(node => ({
    ...node,
    imgList: extractionImg(node.novelContent.contents),
  }))

// imgタグのsrcだけじゃなくてaltも取れるようにするよ
// contents.match(/<img.*?\/\>/g)
// htmlText === node.novelContent.contents
export const extractionImg = htmlText => {
  const regex = /<img.*?\/\>/g
  const imgTags = htmlText.match(regex) || []
  return imgTags.map(tag => ({
    url: retrieveUrl(tag),
    alt: retrieveAlt(tag),
  }))
}

export const addType = (list, type) => {
  if (!list) {
    return []
  }
  const flattenList = list.nodes ? list.nodes : list
  return flattenList.map(item => ({ ...item, type }))
}

// postの場合、typeにはcategoryを入れてしまう
// http://base.com/type/slug
export const addCategory = list => {
  if (!list || !list.length) {
    return []
  }

  return list.map(item => {
    const categoryAsType = item.categories
      ? item.categories.nodes[0].slug
      : ARTICLE_TYPE.POST
    return { ...item, type: categoryAsType }
  })
}

export const getLanguage = lang => (lang === 'cn' ? 'zh' : lang)
export const getTagLanguage = lang =>
  lang === 'cn' ? 'ZH' : lang.toUpperCase()
export const compareDate = (a, b) => (parse(a.date) < parse(b.date) ? 1 : -1)
export const compareModified = (a, b) =>
  parse(a.modified) < parse(b.modified) ? 1 : -1

export const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
