import React from 'react'
import YouTube from 'react-youtube'
import { Query } from 'react-apollo'
import { LIVE_QUERY } from 'src/queries'
import { isDateAfterToday, isDateBeforeToday } from 'src/commonFunctions'

export default class AdvertisementOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isClose: false,
    }
  }
  render() {
    return (
      <Query query={LIVE_QUERY}>
        {({ data, loading }) => {
          if (loading) return <div />
          const live = this.retrieveLive(data)
          if (live) {
            const dataId = live.url.split('embed/')[1]
            dataId.split('/')[0]

            const opts = {
              playerVars: {
                autoplay: 1,
                mute: true,
              },
            }

            const containerClassName = `live_container ${this.getMinimizeClassName()}`

            return (
              <div className={containerClassName}>
                <div
                  className="pc_ui player_ui_minimize"
                  onClick={() =>
                    this.setState({
                      isClose: !this.state.isClose,
                    })
                  }
                >
                  <span />
                </div>
                <div className="video_wrapper">
                  <div className="sp_ui player_ui_fullscreen" />
                  <YouTube
                    videoId={dataId}
                    id="youtube_live_video"
                    className="video_player"
                    containerClassName="video_container"
                    opts={opts}
                    onReady={this._onReady}
                  />
                </div>

                <div className="live_info">
                  <p className="live_heading">
                    <strong>［生放送中］</strong>
                    <br />
                    {live.heading}
                  </p>
                  <nav>
                    <ul>
                      <li className="sp_ui player_ui_sound" />
                      <li className="sp_ui player_ui_fullscreen" />
                    </ul>
                  </nav>
                </div>
              </div>
            )
          }
          return <div />
        }}
      </Query>
    )
  }

  _onReady(event) {
    // ミュート
    const mute_button = document.querySelector('.player_ui_sound')
    mute_button.classList.add('mute')

    mute_button.addEventListener('click', evt => {
      if (event.target.isMuted()) {
        event.target.unMute()
        mute_button.classList.add('unmute')
        mute_button.classList.remove('mute')
      } else {
        event.target.mute()
        mute_button.classList.add('mute')
        mute_button.classList.remove('unmute')
      }
    })

    // フルスクリーン
    const fullscreen_buttons = document.querySelectorAll(
      '.player_ui_fullscreen'
    )
    for (const button of fullscreen_buttons) {
      button.addEventListener('click', evt => {
        const youtube = document.querySelector('#youtube_live_video')
        const requestFullScreen =
          youtube.requestFullScreen ||
          youtube.mozRequestFullScreen ||
          youtube.webkitRequestFullScreen
        if (requestFullScreen) {
          requestFullScreen.bind(youtube)()
        }
      })
    }
  }

  getMinimizeClassName() {
    return this.state.isClose ? 'state_minimize' : ''
  }

  retrieveLive(data) {
    if (!data) {
      return undefined
    }
    // 広告は1つの投稿ですべてを設定するので[0]使用
    const lives = data.adbanners.nodes[0].adbanner.live || []
    // 生放送は期間を満たしたもののうち先頭1件だけ有効
    return lives.filter(
      live => isDateAfterToday(live.start) && isDateBeforeToday(live.end)
    )[0]
  }
}
