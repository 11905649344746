import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { withClientState } from 'apollo-link-state'
import fetch from 'node-fetch'

import { GRAPHQL_BASE_URL } from './constants'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors)
  }
  if (networkError) {
    console.log(networkError)
  }
})

const httpLink = new HttpLink({
  uri: GRAPHQL_BASE_URL,
  fetch,
})

const initialState = { isMenuOpen: false }

const cache = new InMemoryCache()

const resolvers = {
  Mutation: {
    setMenuState: (_, { isMenuOpen }, { cache }) => {
      const data = { isMenuOpen }
      cache.writeData({ data })
      return null
    },
  },
}

const stateLink = withClientState({
  cache,
  defaults: initialState,
  resolvers,
})

const link = ApolloLink.from([errorLink, stateLink, httpLink])
export default new ApolloClient({ link, cache, resolvers })
