import React from 'react'
import uuid from 'uuid/v4'
import { LANG_SELECTION_MAP, BASE_URL } from 'src/constants'

export default () => {
  const domain = BASE_URL.slice(-1) === '/' ? BASE_URL.slice(0, -1) : BASE_URL
  return (
    <span className="language_selection">
      {LANG_SELECTION_MAP.map((lang, idx) => (
        <React.Fragment key={uuid()}>
          {idx > 0 ? <span className="slice"> / </span> : undefined}
          {domain === lang.domain ? (
            <span key={uuid()}>{lang.label}</span>
          ) : (
            <a href={lang.domain} key={uuid()}>
              {lang.label}
            </a>
          )}
        </React.Fragment>
      ))}
    </span>
  )
}
