import React from 'react'
import Modal from 'react-modal'
import all from '../../resource/all.jpeg'
import { myContext } from 'layouts/BasicLayout'

Modal.setAppElement('#___gatsby')

export default () => (
  <myContext.Consumer>
    {context => (
      <div className="modalWrapper">
        <Modal
          isOpen={context.modalIsOpen}
          onRequestClose={context.modalOpen}
          onAfterClose={() => window.scrollTo(0, document.body.scrollHeight)}
          className="modalSearchWindow"
          overlayClassName="modalSearchOverlay"
        >
          <img src={all} />
        </Modal>
      </div>
    )}
  </myContext.Consumer>
)
