// 生クエリが欲しい場合はconsole.log()で出力

import graphql from 'graphql-tag'
import Builder from 'graphql-query-builder-v2'

// プラグインの都合で中国語記事はzhでやってくるので、ZHに変換
const language =
  process.env.LANGUAGE_ALIAS === 'cn'
    ? 'ZH'
    : process.env.LANGUAGE_ALIAS.toUpperCase()

// 共通
const createImageQuery = () => new Builder('image').find('sourceUrl')
const createIsOfficialQuery = () => new Builder('isOfficial').find('isofficial')
const createLanguageQuery = () => new Builder('language').find('slug')

const createTagsQuery = () => {
  const nodes = new Builder('nodes').find('id', 'slug', 'name')
  return new Builder('tags').find(nodes)
}

const createAuthorsQuery = () => new Builder('authors').find('name', 'role')

const createCategoriesQuery = () => {
  const nodes = new Builder('nodes').find('id', 'slug', 'name')
  return new Builder('categories').find(nodes)
}

const createOgpSettingsQuery = () => {
  const image = new Builder('image').find('sourceUrl', 'altText')
  const ogp = new Builder('ogp').find('description', image)
  return new Builder('ogpSettings').find(ogp)
}

const createCreditsQuery = () => {
  const links = new Builder('links').find('twitter', 'url', 'youtube')
  const image = new Builder('image').find('sourceUrl')
  const profile = new Builder('profile').find(image, links, 'texts')
  const profileList = new Builder('... on ProfileList').find(
    'id',
    profile,
    'title',
    'termSlugs'
  )
  const user = new Builder('user').find(profileList)
  const credit = new Builder('credit').find('role', user)
  const credits = new Builder('credits').find(credit)
  return new Builder('credits').find(credits)
}

const createIndexCharacterQuery = () => {
  const image = new Builder('image').find('sourceUrl', 'altText')
  return new Builder('characters').find('name', 'introduction', image)
}

/**
 * type: postBy | interviewBy | novelBy | comicBy
 */
const getCredits = (slug, type) => {
  return new Builder(type).filter({ slug }).find('id', createCreditsQuery())
}

const createLinkToIndexQuery = () => {
  const linktoindex = new Builder('linktoindex').find('title', 'url')
  return new Builder('linkToIndex').find(linktoindex)
}

const createInfinityScrollLinkQuery = () => {
  const infinityscrolllink = new Builder('infinityscrolllink').find(
    'title',
    'url'
  )
  return new Builder('infinityScrollLink').find(infinityscrolllink)
}

const createPostContents = () => new Builder('contents').find('texts')

const createInterviewContents = () => {
  const block = new Builder('block').find('content', 'head', 'iscolumn')
  return new Builder('interviewContents').find('leading', block)
}
const createNovelContents = () => {
  const cover = new Builder('cover').find('id', 'sourceUrl')
  const freeEntry = new Builder('freeentry').find('subject', 'contents')
  return new Builder('novelContent').find(
    createAuthorsQuery(),
    'contents',
    cover,
    freeEntry
  )
}
const createComicContents = () => {
  const page = new Builder('page').find('id', 'sourceUrl')
  const pages = new Builder('pages').find(page)
  const cover = new Builder('cover').find('id', 'sourceUrl')
  const freeEntry = new Builder('freeentry').find('subject', 'contents')
  return new Builder('comicContents').find(
    createAuthorsQuery(),
    'viewerurl',
    'niconicourl',
    cover,
    pages,
    freeEntry
  )
}

const createIndexCover = () => {
  const cover = new Builder('cover').find('sourceUrl')
  return new Builder('indexCover').find(cover)
}

const createIndexContent = () => {
  const link = new Builder('link').find('url')
  const item = new Builder('item').find('num', 'date', 'title', link)
  return new Builder('index').find(
    'about',
    createAuthorsQuery(),
    createIndexCharacterQuery(),
    item
  )
}

// 広告系
const createAdbannersQueryBase = targetQuery => {
  const adbanner = new Builder('adbanner').find(targetQuery)
  const nodes = new Builder('nodes').find(adbanner)
  return `
    adbanners(where: {language: ${language}}) {
      ${nodes}
    }
  `
}

const createAdbannersQueryDouble = (targetQuery1, targetQuery2) => {
  const adbanner = new Builder('adbanner').find(targetQuery1, targetQuery2)
  const nodes = new Builder('nodes').find(adbanner)
  return `
    adbanners(where: {language: ${language}}) {
      ${nodes}
    }
  `
}

const createFullSizeBannerQuery = () => {
  const fullsizeBackground = new Builder('fullsizeBackground').find(
    'start',
    'end',
    'link',
    createImageQuery()
  )
  return createAdbannersQueryBase(fullsizeBackground)
}

const createFireworkBannerQuery = () => {
  const fullsizeBackground = new Builder('fullsizeBackground').find(
    'start',
    'end',
    'link',
    createImageQuery()
  )

  const firework = new Builder('firework').find(
    'start',
    'end',
    'link',
    createImageQuery()
  )
  return createAdbannersQueryDouble(fullsizeBackground, firework)
}

const createFestivalAndStaticsQuery = () => {
  const festivalBanner = new Builder('festivalBanner').find(
    'name',
    'start',
    'end',
    'link',
    createImageQuery()
  )

  const link = new Builder('link').find('url')
  const statics = new Builder('statics').find(link, 'order')
  return createAdbannersQueryDouble(festivalBanner, statics)
}

const createLiveQuery = () => {
  const live = new Builder('live').find('start', 'end', 'url', 'heading')
  return createAdbannersQueryBase(live)
}

const createBannerQuery = () => {
  const banners = new Builder('banners').find(
    'name',
    'start',
    'end',
    'link',
    createImageQuery()
  )

  const link = new Builder('link').find('url')
  const statics = new Builder('statics').find(link, 'order')

  return createAdbannersQueryDouble(banners, statics)
}
const createIndexesInterviewQuery = () => createIndexesQuery('indexesInterview')
const createIndexesNovelQuery = () =>
  createIndexesQuery('indexesNovel', createIndexCover())
const createIndexesComicQuery = () =>
  createIndexesQuery('indexesComic', createIndexCover())

const createInitQuery = () => {
  return new Builder('wp').find(
    createIndexesInterviewQuery(),
    createIndexesNovelQuery(),
    createIndexesComicQuery(),
    createInitCategoryQuery(),
    createBannerQuery()
  )
}

// initの時に使うやつら
const createArticlesQuerybase = (rootName, contents, cursor) => {
  const args = [
    'id',
    'title',
    'date',
    'slug',
    ...contents,
    createIsOfficialQuery(),
    createTagsQuery(),
    createOgpSettingsQuery(),
    createLinkToIndexQuery(),
    createInfinityScrollLinkQuery(),
  ]
  const nodes = new Builder('nodes').find(args)

  const pageInfo = new Builder('pageInfo').find('endCursor', 'hasNextPage')
  const article = `
    ${rootName}(first: 100, where: {language: ${language}}, after: "${cursor}") {
      ${nodes}
      ${pageInfo}
    }
  `

  return new Builder('wp').find(article)
}

const createIndexesQuery = (rootName, optionalQuery) => {
  const nodes = new Builder('nodes').find(
    'id',
    'title',
    'date',
    'modified',
    'slug',
    createIsOfficialQuery(),
    createIndexContent(),
    createTagsQuery(),
    createInfinityScrollLinkQuery(),
    createOgpSettingsQuery(),
    optionalQuery || ''
  )

  return `
    ${rootName}(first: 100, where: {language: ${language}}) {
      ${nodes}
    }
  `
}

const createInitPostsQuery = rootName => {
  const args = [
    'id',
    'title',
    'slug',
    'date',
    createTagsQuery(),
    createOgpSettingsQuery(),
    createLanguageQuery(),
  ]
  if (rootName === 'posts') {
    args.push(createCategoriesQuery())
  }
  const nodes = new Builder('nodes').find(args)
  return new Builder(rootName)
    .find(nodes)
    .filter({ first: 100 })
    .toString()
}

const createInitCategoryQuery = () => {
  const nodes = new Builder('nodes').find(
    'name',
    'slug',
    createInitPostsQuery('posts')
  )
  return new Builder('categories')
    .find(nodes)
    .filter({ first: 100 })
    .toString()
}

const createInitTagsQueryRecursion = cursor => {
  const nodes = new Builder('nodes').find(
    'name',
    'slug',
    createInitPostsQuery('posts'),
    createInitPostsQuery('interviews'),
    createInitPostsQuery('novels'),
    createInitPostsQuery('comics'),
    createInitPostsQuery('indexesInterview'),
    createInitPostsQuery('indexesNovel'),
    createInitPostsQuery('indexesComic'),
    createInitPostsQuery('characters')
  )
  const pageInfo = new Builder('pageInfo').find('endCursor', 'hasNextPage')
  const tags = new Builder('tags')
    .find(nodes, pageInfo)
    .filter({ first: 100, after: cursor })

  return new Builder('wp').find(tags)
}

const createAllTags = cursor => {
  const language = new Builder('language').find('locale', 'code', 'name')
  const nodes = new Builder('nodes').find('name', 'slug', language)
  const pageInfo = new Builder('pageInfo').find('endCursor', 'hasNextPage')
  const tags = new Builder('tags')
    .find(nodes, pageInfo)
    .filter({ first: 100, after: cursor })

  return new Builder('wp').find(tags)
}

export const INIT_QUERY = `{${createInitQuery()}}`
export const INIT_TAGS_QUERY = cursor =>
  `{${createInitTagsQueryRecursion(cursor)}}`
export const INIT_POSTS_QUERY = cursor =>
  `{${createArticlesQuerybase(
    'posts',
    [createCategoriesQuery(), createPostContents()],
    cursor
  )}}`
export const INIT_INTERVIEW_QUERY = cursor =>
  `{${createArticlesQuerybase(
    'interviews',
    [createInterviewContents()],
    cursor
  )}}`
export const INIT_NOVELS_QUERY = cursor =>
  `{${createArticlesQuerybase('novels', [createNovelContents()], cursor)}}`
export const INIT_COMICS_QUERY = cursor =>
  `{${createArticlesQuerybase('comics', [createComicContents()], cursor)}}`

export const FULL_SIZE_BANNER_QUERY = graphql`{${createFullSizeBannerQuery()}}`
export const FIREWORK_BANNER_QUERY = graphql`{${createFireworkBannerQuery()}}`
export const FESTIVAL_AND_STATICS_QUERY = graphql`{${createFestivalAndStaticsQuery()}}`
export const LIVE_QUERY = graphql`{${createLiveQuery()}}`
export const HEADER_BANNER_QUERY = graphql`{${createBannerQuery()}}`
export const ALL_TAGS_QUERY = cursor => `{${createAllTags(cursor)}}`

export const getCreditsQuery = (slug, type) =>
  graphql`{${getCredits(slug, type)}}`
export const getPostQuery = slug => graphql`{${createPostQuery(slug)}}`
export const getInterviewQuery = slug =>
  graphql`{${createInterviewQuery(slug)}}`
export const getNovelQuery = slug => graphql`{${createNovelQuery(slug)}}`
export const getComicQuery = slug => graphql`{${createComicQuery(slug)}}`

export const GET_MENU_STATE_QUERY = graphql`
  {
    isMenuOpen @client
  }
`

export const SET_MENU_STATE_QUERY = graphql`
  mutation SetMenuState($isMenuOpen: Boolean!) {
    setMenuState(isMenuOpen: $isMenuOpen) @client
  }
`

// プレビュー時にとってくるやつら
const createArticleBase = (rootName, slug, contents) => {
  const args = [
    'id',
    'title',
    'date',
    'slug',
    'status',
    contents,
    createIsOfficialQuery(),
    createTagsQuery(),
    createOgpSettingsQuery(),
    createLinkToIndexQuery(),
    createInfinityScrollLinkQuery(),
    createLanguageQuery(),
  ]
  if (rootName === 'postBy') {
    args.push(createCategoriesQuery())
  }
  return new Builder(rootName)
    .filter({ slug })
    .find(args)
    .toString()
}

const createPostQuery = slug =>
  createArticleBase('postBy', slug, createPostContents())
const createInterviewQuery = slug =>
  createArticleBase('interviewBy', slug, createInterviewContents())
const createNovelQuery = slug =>
  createArticleBase('novelBy', slug, createNovelContents())
const createComicQuery = slug =>
  createArticleBase('comicBy', slug, createComicContents())
