import React from 'react'
import ReactGA from 'react-ga'

export default ({ name, link, image }) => (
  <div
    className="banner"
    onClick={() =>
      ReactGA.event({
        category: link,
        action: 'click banner',
        value: 1,
      })
    }
  >
    <a href={link} target="_blank" rel="noopener">
      <img lazyload src={image.sourceUrl} alt={name} />
    </a>
  </div>
)
