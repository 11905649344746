import React from 'react'
import { Query } from 'react-apollo'
import {
  ORIGINAL_HP,
  ZUN_BLOG,
  ZUN_TWITTER,
  TWITTER_ACCOUNT,
  TWITTER_ACCOUNT_EN,
  TWITTER_LINK,
  TWITTER_LINK_EN,
  CONTACT_PRESS,
  MAIL_FORM,
  MAIL,
  MAIL_EN,
  LANGUAGE_ALIAS,
} from 'src/constants'
import { GET_MENU_STATE_QUERY } from 'src/queries'
import { MENUS } from 'src/constants'
import MenuIcon from 'components/atoms/MenuIcon'
import LanguageSelection from 'components/LanguageSelection'
import { intl } from 'src/constants'
import Search from './GoogleCustomSearch'
import ClientOnly from './hooks/ClientOnly'

export default () => (
  <Query query={GET_MENU_STATE_QUERY}>
    {({ data }) => (
      <>
        {// 論理 && 演算子によるインライン If
        // https://ja.reactjs.org/docs/conditional-rendering.html#inline-if-with-logical--operator
        data.isMenuOpen && (
          <div className="menu_overlay">
            <div className="overlay_inner">
              <div className="lang_selection">
                <p>
                  {intl.getMessage('language')}：<LanguageSelection />
                </p>
              </div>
              <ClientOnly>
                <Search lang={LANGUAGE_ALIAS} />
              </ClientOnly>
              <div
                className={
                  LANGUAGE_ALIAS === 'en'
                    ? 'menu_container_en'
                    : 'menu_container'
                }
              >
                <MenuIcon content={MENUS.TOP} />
                <MenuIcon content={MENUS.NEWS} />
                <MenuIcon content={MENUS.COMIC} />
                <MenuIcon content={MENUS.NOVEL} />
                <MenuIcon content={MENUS.INTERVIEWS} />
                <MenuIcon content={MENUS.REPORTS} />
                <MenuIcon content={MENUS.COLUMNS} />
                <MenuIcon content={MENUS.FANZINE_REVIEW} />
                <MenuIcon content={MENUS.MUSIC_REVIEW} />
                <MenuIcon content={MENUS.GAME_REVIEW} />
                <MenuIcon content={MENUS.SERIES} />
                <MenuIcon content={MENUS.WHERE} />
                <MenuIcon content={MENUS.EASTERNPLAYING} />
                <MenuIcon content={MENUS.CHARACTER} />
              </div>
              <div className="links">
                <p className="original_link">
                  <a href={ORIGINAL_HP} target="_brank" rel="nofollow">
                    {intl.getMessage('original_hp')}
                  </a>
                </p>
                <p className="original_link">
                  <a href={ZUN_BLOG} target="_brank" rel="nofollow">
                    {intl.getMessage('zun_blog')}
                  </a>
                </p>
                <p className="original_link">
                  <a href={ZUN_TWITTER} target="_brank" rel="nofollow">
                    {intl.getMessage('zun_hp')}
                  </a>
                </p>
                <p className="contact_link">
                  {LANGUAGE_ALIAS === 'en'
                    ? intl.getMessage('link_twitter_jp')
                    : intl.getMessage('link_twitter')}

                  <a href={TWITTER_LINK} target="_brank" rel="nofollow">
                    {TWITTER_ACCOUNT}
                  </a>
                </p>
                {LANGUAGE_ALIAS === 'en' && (
                  <p className="contact_link">
                    {intl.getMessage('link_twitter_en')}
                    {LANGUAGE_ALIAS === 'en' && (
                      <a href={TWITTER_LINK_EN} target="_brank" rel="nofollow">
                        {TWITTER_ACCOUNT_EN}
                      </a>
                    )}
                  </p>
                )}
                {LANGUAGE_ALIAS === 'en' ? (
                  <p className="contact_link">Mail: {MAIL_EN}</p>
                ) : (
                  LANGUAGE_ALIAS !== 'ja' && (
                    <p className="contact_link">Mail: {MAIL}</p>
                  )
                )}
                {LANGUAGE_ALIAS === 'ja' && (
                  <p className="contact_link">
                    {intl.getMessage('mail_form')}
                    <a href={MAIL_FORM} target="_brank" rel="nofollow">
                      {intl.getMessage('here')}
                    </a>
                  </p>
                )}
                {LANGUAGE_ALIAS === 'ja' && (
                  <p className="contact_link">
                    {intl.getMessage('contact_press')}
                    <a href={CONTACT_PRESS} target="_brank" rel="nofollow">
                      {intl.getMessage('here')}
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )}
  </Query>
)
