import React from 'react'
import Helmet from 'react-helmet'
import {
  SITE_TITLE,
  LOCALE,
  GOOGLE_VERIFICATION,
  SITE_DESCRIPTION,
  intl,
} from 'src/constants'
import LiveContainer from 'components/LiveContainer'
import Header from 'components/Header'
import MenuButton from 'components/atoms/MenuButton'
import MenuOverlay from 'components/MenuOverlay'
import AdobeFonts from 'components/atoms/AdobeFonts'
import Favicon from 'img/yukkurifavicon.png'
import CharacterShowModal from 'components/CharacterShowModal'
export const myContext = React.createContext()

import 'styles/all.scss'

export default ({ children }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  return (
    <div className="app_root">
      <Helmet
        title={`Home | ${SITE_TITLE}`}
        link={[{ rel: 'icon', href: Favicon }]}
        meta={[
          { name: 'referrer', content: 'origin' },
          { name: 'google-site-verification', content: GOOGLE_VERIFICATION },
        ]}
      >
        <html lang={LOCALE} />
      </Helmet>
      <AdobeFonts />
      <div className="sticky_content">
        <LiveContainer />
        <Header />
      </div>
      <div className="service_root">
        <div className="discription">
          <p className="marquee">
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {intl.getMessage('site_description')}
            </span>
          </p>
          <p className="marquee">
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {intl.getMessage('site_description')}
            </span>
          </p>
          <div>
            <a href={SITE_DESCRIPTION} rel="nofollow">
              {intl.getMessage('read_more')}
            </a>
          </div>
        </div>
        <myContext.Provider
          value={{
            modalIsOpen,
            modalOpen: () => setIsOpen(!modalIsOpen),
          }}
        >
          {children}
          <CharacterShowModal />
        </myContext.Provider>
        <MenuButton />
      </div>
      <MenuOverlay />
    </div>
  )
}
