import React from 'react'
import ReactGA from 'react-ga'
import { ApolloProvider } from 'react-apollo'
import graphql_client from 'src/graphqlClient'
import Layout from 'layouts/BasicLayout'
import { GA_ID } from 'src/constants'

export default ({ element }) => {
  ReactGA.initialize(GA_ID)
  return (
    <ApolloProvider client={graphql_client}>
      <Layout>{element}</Layout>
    </ApolloProvider>
  )
}
