import button_menu_hakurei from 'img/button_menu_hakurei.png'
import icon_menu_character from 'img/icon_menu_character.png'
import icon_menu_whats from 'img/icon_menu_whats.png'
import icon_menu_pickup from 'img/icon_menu_pickup.png'
import icon_menu_comic from 'img/icon_menu_comic.png'
import icon_menu_novel from 'img/icon_menu_novel.png'
import icon_menu_interview from 'img/icon_menu_interview.png'
import icon_menu_report from 'img/icon_menu_tag.png'
import icon_menu_column from 'img/icon_character_personality.png'
import icon_menu_game from 'img/icon_menu_game.png'
import icon_menu_music from 'img/icon_menu_music.png'
import icon_character_haikei from 'img/icon_character_haikei.png'
import icon_menu_series from 'img/icon_menu_series.png'
import icon_menu_about from 'img/icon_menu_about.png'

import { buildIntlContext } from 'src/intlFunction'

export const intl = buildIntlContext(__LOCALE__)

export const SITE_TITLE = intl.getMessage('touhougarakutasousi')
export const ORIGINAL_HP = 'https://www16.big.or.jp/~zun/'
export const ZUN_BLOG = 'https://kourindou.exblog.jp/'
export const ZUN_TWITTER = 'https://twitter.com/korindo'
export const TOUHOU_NEWS = 'https://touhou-project.news'
export const SITE_DESCRIPTION = 'https://touhougarakuta.com/where-are-you/sokan'
export const TWITTER_ACCOUNT = '@touhou_station'
export const TWITTER_ACCOUNT_EN = '@Garakuta_EN'
export const TWITTER_LINK = 'https://twitter.com/touhou_station'
export const TWITTER_LINK_EN = 'https://twitter.com/Garakuta_EN'
export const CONTACT_PRESS = 'https://touhougarakuta.com/news/press'
export const MAIL_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSdtZpHXbsIM8KNlDW33aFr1ZI-b6selP2ElUhPcDratLeP7_g/viewform'
export const MAIL = 'touhou.garakuta@gmail.com'
export const MAIL_EN = 'en.garakuta.soushi@gmail.com'
export const SITE_TITLE_SHORT = intl.getMessage('touhougarakutasousi_short')
export const GRAPHQL_BASE_URL = __GRAPHQL_URL__
export const LOCALE = __LOCALE__
export const LANGUAGE_ALIAS = __LANGUAGE_ALIAS__
// https://stg-thwm.netlify.com/
// はパスワードで保護されているのでOGPテストは
// こいつをつかう https://chrome.google.com/webstore/detail/localhost-open-graph-chec/gcbnmkhkglonipggglncobhklaegphgn?hl=ja
export const BASE_URL = __BASE_URL__
export const GA_ID = __GA_ID__
export const GOOGLE_VERIFICATION = __GOOGLE_VERIFICATION__

// リスト表示の時にグループ化する記事の数
export const GROUPED_ARTICLE_NUMBER = 5
export const CAROUSEL_ARTICLE_NUMBER = 6

// 新着からもっと見るページへ遷移した際の表示するページ数
export const NEW_POST_PAGE_ARTICLE_NUMBER = 4000

export const MENUS = {
  TOP: {
    TITLE: 'TOP',
    IMAGE: button_menu_hakurei,
    TO: '/',
    SLUG: '/',
  },
  CHARACTER: {
    TITLE: intl.getMessage('story_introduction'),
    IMAGE: icon_menu_character,
    TO: '/characters',
    SLUG: 'character',
  },
  EASTERNPLAYING: {
    TITLE: intl.getMessage('eastern_playing'),
    IMAGE: icon_menu_whats,
    TO: '/eastern_playing',
    SLUG: 'eastern-playing',
  },
  PICKUP: {
    TITLE: intl.getMessage('soaring'),
    IMAGE: icon_menu_pickup,
    TO: '/',
    SLUG: 'pickup',
  },
  NOVEL: {
    TITLE: intl.getMessage('novel'),
    IMAGE: icon_menu_novel,
    TO: '/novels',
    SLUG: 'novel',
  },
  COMIC: {
    TITLE: intl.getMessage('comic'),
    IMAGE: icon_menu_comic,
    TO: '/comics',
    SLUG: 'comic',
  },
  INTERVIEWS: {
    TITLE: intl.getMessage('interview'),
    IMAGE: icon_menu_interview,
    TO: '/tags/interviews',
    SLUG: 'interview',
  },
  REPORTS: {
    TITLE: intl.getMessage('report'),
    IMAGE: icon_menu_report,
    TO: '/tags/reports',
    SLUG: 'report',
  },
  COLUMNS: {
    TITLE: intl.getMessage('column'),
    IMAGE: icon_menu_column,
    TO: '/tags/columns',
    SLUG: 'column',
  },
  SERIES: {
    TITLE: intl.getMessage('series'),
    IMAGE: icon_menu_pickup,
    TO: '/series',
    SLUG: 'series',
  },
  GAME_REVIEW: {
    TITLE: intl.getMessage('game_review'),
    IMAGE: icon_menu_game,
    TO: '/game_review',
    SLUG: 'game-review',
  },
  MUSIC_REVIEW: {
    TITLE: intl.getMessage('music_review'),
    IMAGE: icon_menu_music,
    TO: '/music_review',
    SLUG: 'music-review',
  },
  FANZINE_REVIEW: {
    TITLE: intl.getMessage('book_review'),
    IMAGE: icon_character_haikei,
    TO: '/book_review',
    SLUG: 'book-review',
  },
  NEWS: {
    TITLE: intl.getMessage('news'),
    IMAGE: icon_menu_series,
    TO: '/news',
    SLUG: 'news',
  },
  WHERE: {
    TITLE: intl.getMessage('about'),
    IMAGE: icon_menu_about,
    TO: '/where_are_you',
    SLUG: 'where-are-you',
  },
}

export const LANG_SELECTION_MAP = [
  { label: '日本語', domain: 'https://touhougarakuta.com' },
  { label: '中文', domain: 'https://cn.touhougarakuta.com' },
  { label: '한국어', domain: 'https://ko.touhougarakuta.com' },
  { label: 'English', domain: 'https://en.touhougarakuta.com' },
]
