import React from 'react'

const getCx = lang => {
  if (lang === 'ja') {
    return 'a1c3219116174f6a1'
  } else if (lang === 'cn') {
    return '5ad26f217333f11dc'
  } else if (lang === 'ko') {
    return 'e971d9ac2bf639c17'
  } else if (lang === 'en') {
    return '3919698c12866830c'
  } else {
    return 'a1c3219116174f6a1'
  }
}

const search = lang => {
  const cx = getCx(lang.lang)
  let gcse = document.createElement('script')
  gcse.type = 'text/javascript'
  gcse.async = true
  gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
  let s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(gcse, s)
  return <div className="gcse-search"></div>
}

export default search
