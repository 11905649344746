import ja from 'src/i18n/ja'
import en from 'src/i18n/en'
import cn from 'src/i18n/cn'
import ko from 'src/i18n/ko'

const messages = {
  ja,
  en,
  cn,
  ko,
}

export const buildIntlContext = locale => {
  const message = messages[locale]
  return {
    getMessage: id => {
      const msg = message[id]
      return msg
    },
  }
}
