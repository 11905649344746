import React from 'react'
import { format, isBefore, isAfter } from 'date-fns'
import defaultImage from 'img/ogp_reimu.png'
import { ARTICLE_TYPE } from 'src/statics'
import { MENUS, BASE_URL } from 'src/constants'

export const nl2br = text => {
  if (!text) {
    return null
  }
  var regex = /(\n)/g
  return text.split(regex).map(function(line) {
    if (line.match(regex)) {
      return React.createElement('br')
    } else {
      return line
    }
  })
}

export const formatDate = date => format(date, 'YYYY/MM/DD')
export const formatDateTime = dateTime => format(dateTime, 'YYYY/MM/DD HH:mm')
const domain = BASE_URL.slice(-1) === '/' ? BASE_URL.slice(0, -1) : BASE_URL
export const getImageUrl = ogp =>
  ogp && ogp.image ? ogp.image.sourceUrl : domain + defaultImage
export const getAltText = ogp => (ogp.altText ? ogp.image.altText : '')

// urlの文字列を"/"の有無を考慮して組み立てる
export const urlJoin = urlElements => {
  if (!urlElements || urlElements.length === 0) return null
  return urlElements
    .map(url => (url && url[0] === '/' ? url.slice(1) : url))
    .map(url => (url && url[url.length - 1] === '/' ? url.slice(0, -1) : url))
    .join('/')
}

// 内部リンクにする
export const createInnerLink = url => (url[0] === '/' ? url : `/${url}`)

export const stripLinkToIndexToURL = object => {
  if (!object) return null
  if (object.hasOwnProperty('url')) {
    return object.url
  } else if (object.hasOwnProperty('linktoindex')) {
    return object.linktoindex.url
  } else {
    return null
  }
}

export const stripLinkToIndex = object => {
  if (!object) return null
  if (object.hasOwnProperty('url')) {
    return object
  } else if (object.hasOwnProperty('linktoindex')) {
    return object.linktoindex
  } else {
    return null
  }
}

// urlからtypeをとる
// 例 "https://stg.thwm.cfbx.jp/type/slug" => "type"
export const convertUrlToType = url => {
  if (!url || typeof url !== 'string') return null
  // urlの末尾が/で終わる場合動かないので末尾の/を取る
  const formattedString = url[url.length - 1] === '/' ? url.slice(0, -1) : url
  const splittedStr = formattedString.split('/')
  const type = splittedStr[splittedStr.length - 2]
  // POSTの場合、カテゴリ名がTypeの部分に入ってる場合があるので、ARTICLE_TYPEに載ってないやつやニュースなどはPOSTにする
  if (ARTICLE_TYPES.includes(type) && !POST_ARTICLES.includes(type)) {
    return type
  } else return ARTICLE_TYPE.POST
}

const ARTICLE_TYPES = Object.keys(ARTICLE_TYPE).map(key => ARTICLE_TYPE[key])
const POST_ARTICLES = [
  ARTICLE_TYPE.GAMEREVIEW,
  ARTICLE_TYPE.MUSICREVIEW,
  ARTICLE_TYPE.FANZINEREVIEW,
  ARTICLE_TYPE.NEWS,
]

// urlからinnerLinkをとる
// 例 "https://stg.thwm.cfbx.jp/type/slug" => "/type/slug"
export const convertUrlToInnerLink = url => {
  try {
    return new URL(url).pathname
  } catch (e) {
    return null
  }
}

export const isDateBeforeToday = dateTime =>
  isBefore(new Date(), formatDateTime(dateTime))
export const isDateAfterToday = dateTime =>
  isAfter(new Date(), formatDateTime(dateTime))

export const stripOfficial = isOfficial =>
  isOfficial && isOfficial.isofficial && isOfficial.isofficial === 'official'

export const convertArticleType2menus = articleType => {
  switch (articleType) {
    case ARTICLE_TYPE.INTERVIEW:
      return MENUS.INTERVIEWS
    case ARTICLE_TYPE.REPORT:
      return MENUS.REPORTS
    case ARTICLE_TYPE.COLUMN:
      return MENUS.COLUMNS
    case ARTICLE_TYPE.NOVEL:
      return MENUS.NOVEL
    case ARTICLE_TYPE.COMIC:
      return MENUS.COMIC
    case ARTICLE_TYPE.INDEXINTERVIEW:
      return MENUS.SERIES
    case ARTICLE_TYPE.INDEXNOVEL:
      return MENUS.SERIES
    case ARTICLE_TYPE.INDEXCOMIC:
      return MENUS.SERIES
    case ARTICLE_TYPE.GAMEREVIEW:
      return MENUS.GAME_REVIEW
    case ARTICLE_TYPE.MUSICREVIEW:
      return MENUS.MUSIC_REVIEW
    case ARTICLE_TYPE.FANZINEREVIEW:
      return MENUS.FANZINE_REVIEW
    case ARTICLE_TYPE.NEWS:
      return MENUS.NEWS
    case ARTICLE_TYPE.WHERE:
      return MENUS.WHERE
    case ARTICLE_TYPE.EASTERNPLAYING:
      return MENUS.EASTERNPLAYING
    default:
      return MENUS.INTERVIEWS
  }
}

// location.searchで取れる?foo=123&bar=456なものを { foo: 123, bar: 456 }にして返す
export const getQueryParams = search =>
  search
    .slice(1)
    .split('&')
    .map(val => val.split('='))
    .reduce((query, item) => {
      query[item[0]] = item[1]
      return query
    }, {})

/**
 * 工事中であるかどうかを調べる関数
 * slugが非公開対象に一致しているかつローカル環境以外の時、trueを返します。
 * @param {*} slug 対象slug
 */
export const isUnderConstruction = slug =>
  __GATSBY_ACTIVE_ENV__ !== 'dev' && [MENUS.CHARACTER.SLUG].includes(slug)
